import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class IdiomaService {
  langage: string;
  langages: string[];
  selectLangages: any[] = [
    {
      lang: 'WORDS.ESPANOL',
      code: 'es',
    },
    {
      lang: 'WORDS.INGLES',
      code: 'en',
    },
  ];

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService
  ) {}

  async initLangage() {
    await this.storageService.init();

    this.langage = await this.storageService.get('langage');
    if (!this.langage) {
      this.langage = null;
    }

    /* Siglas según la Lista de códigos de idioma ISO 639-1 */
    this.translateService.addLangs(['es', 'en', 'de', 'pt']);
    this.langages = this.translateService.getLangs();

    this.translateService.setDefaultLang(this.langage);

    await this.changeLangage(this.langage);
  }

  async changeLangage(langage: string) {
    const useLangage = langage ? langage : 'es';
    this.translateService.use(useLangage);
    await this.storageService.set('langage', langage);
    this.langage = langage;
  }
}
