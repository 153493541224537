/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { ToastService } from '../toast.service';
import { LoginService } from '../../login/services/login.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralesInterceptor implements HttpInterceptor {
  token: string;

  constructor(
    private toastService: ToastService,
    private loginService: LoginService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.token = this.loginService.token;
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      Authorization: `bearer ${this.token}`,
    });
    if (req.url.includes('mapbox')) {
      return next.handle(req);
    }
    const reqClone = req.clone({
      headers,
    });

    return new Observable((observer) => {
      next.handle(reqClone).subscribe(
        (res: HttpResponse<any>) => {
          if (res instanceof HttpResponse) {
            observer.next(res);
          }
        },
        (error: HttpErrorResponse) => {
          this.manageError(error, observer);
          observer.error(error);
        }
      );
    });
    // return next.handle(reqClone).pipe(catchError(this.manageError));
  }

  manageError(error: HttpErrorResponse, observer) {
    if(error.url.includes('i18n')) {
      return;
    }else {
      if(error.status === 0 || error.status === 500 ){
        this.toastService.error(error.error.errors);
      }
    }
    return;
  }
}
