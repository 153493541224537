import { Injectable } from '@angular/core';
import { GoogleAuthProvider, getAuth, FacebookAuthProvider, OAuthProvider, getRedirectResult, signInWithRedirect, signInWithCredential, onAuthStateChanged  } from "firebase/auth";
import { initializeApp }from "@firebase/app"
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { InitPushNotificationService } from '../../services/init-push-notification.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { ToastService } from '../../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  googleProvider = new GoogleAuthProvider();
  facebookProvider = new FacebookAuthProvider();
  appleProvider = new OAuthProvider('apple.com')
  auth = getAuth(initializeApp(environment.firebaseConfig));

  constructor( 
    private loginService:LoginService,
    private initPushService: InitPushNotificationService,
    private storage:StorageService,
    private router: Router,
    private loadService: LoadingService,
    private toastSv: ToastService
  ) {

    const login = localStorage.getItem('loginIn');
    if(login){
      this.loadService.show('Iniciando Sesion...')
    }

    getRedirectResult(this.auth).then(async res => {
      console.log(res);
         
      this.loadService.hide();
      localStorage.removeItem('loginIn');
      if(!res) return
      const { displayName, emailVerified, email, uid, providerId } = res.user;    
      const tokenPush = this.initPushService.tokenUser;
      const country = await this.storage.get('country');
      if(tokenPush){ this.storage.set('tokenPush', tokenPush)}

      const data = {
        displayName, 
        emailVerified, 
        email, uid, 
        providerId, 
        tokenPush,
        country
      }
      this.loginService.login(data).subscribe(user => {
        this.loginService.saveToken(user.token.token);
        this.loginService.saveUser(user.user);
        
        if(user?.user?.rol_id === 2) {
          this.router.navigate(['/admin/']);
        }
        this.router.navigate(['/login/perfil']);
      }, err => {
        if(err.error.data){
          this.toastSv.show(err.error.data);
        }
      })
    }).catch(err => {
      this.loadService.hide();
      localStorage.removeItem('loginIn');
    })
  }

  loginGoogle(){
    localStorage.setItem('loginIn', '1');
    signInWithRedirect(this.auth, this.googleProvider).then();
  }

  loginApple(){
    localStorage.setItem('loginIn', '1');
    signInWithRedirect(this.auth, this.appleProvider).then();
  }

  loginFacebook(){
    localStorage.setItem('loginIn', '1');
    signInWithRedirect(this.auth, this.facebookProvider).then();
  }

  async signOutApp(){
    await this.auth.signOut()
    return 'Signed out';
  }
}
