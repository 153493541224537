// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  path: window.location.origin,
  mapBoxToken:
    'pk.eyJ1IjoicGFuYWRlcm9zIiwiYSI6ImNsM3NwczNxNjAxcGkzYnFwcXcwcG53cXAifQ.uN4awo0lVvaTyOrHVIPOhA',
  production: false,
  // url: 'http://italo:3333',
  // url: 'https://api.padeirosdomundo.com',
  // url: 'https://h1ktmf8p-3332.use2.devtunnels.ms',
  url: 'https://api.checardirectorio.com',
  phone: '+584147193884',
  email: 'info@checardirectorio.com',
  firebaseConfig: {
    apiKey: 'AIzaSyCUZIDcI_uzk8nc62puOwBQAFVFejOfZYw',
    authDomain: 'panaderosdelmundo-9da48.firebaseapp.com',
    projectId: 'panaderosdelmundo-9da48',
    storageBucket: 'panaderosdelmundo-9da48.appspot.com',
    messagingSenderId: '221815756041',
    appId: '1:221815756041:web:14d8baece829c8a593afd8',
    measurementId: 'G-2J9WF0C53R',
    vapidKey:
      'BCaJkrr6z3JbQUGdxCQe9PcW4y8obcnQnYrSWp99ZvhFWvpkK05PQ-GBfxmqSN5Nx9W7pBm5RGW18WbnSYDDdbk',
  },
  socialMedia: [
    // {
    //   name: 'pinterest',
    //   url: '#',
    //   img: '/assets/img/icons/logo-pinterest-white.svg',
    // },
    // {
    //   name: 'facebook',
    //   url: 'https://www.facebook.com/Panaderos-del-mundo-108069181707927',
    //   img: 'logo-facebook',
    // },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/checardirectorio',
      img: 'logo-instagram',
    },
    // {
    //   name: 'youtube',
    //   url: 'https://www.youtube.com/channel/UCbLzdu2-4ZH2DTMeNDfChXw',
    //   img: 'logo-youtube',
    // },
    // {
    //   name: 'twitter',
    //   url: '#',
    //   img: '/assets/img/icons/TWITTER_icon-icons.com_62686.svg',
    // },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
