import { Component, OnInit, HostListener } from '@angular/core';
import { IdiomaService } from './generales/services/idioma.service';
import { InitPushNotificationService } from './generales/services/init-push-notification.service';
import { PaisService } from './generales/services/pais.service';
import { Platform } from '@ionic/angular';
import { FirebaseAuthService } from './generales/login/services/firebase-auth.service';
import { ToastService } from './generales/services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  installed: string;
  saveDeferred;
  deferredPrompt;

  constructor(
    private idiomaService: IdiomaService,
    private paisService: PaisService,
    private initPush: InitPushNotificationService,
    private platform: Platform,
    fbAuth: FirebaseAuthService,
    private toastSv: ToastService
  ) {
    initPush
      .requestPermission()
      .then()
      .catch((err) => {
        console.log(err);
      });
    this.initServices();
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  async onbeforeinstallprompt(event: Event) {
    this.deferredPrompt = event;
    this.saveDeferred = event;
  }

  @HostListener('window:appinstalled', ['$event'])
  async disableApp(event: Event) {
    this.deferredPrompt = undefined;
    if (!this.platform.is('pwa')) {
      this.deferredPrompt = this.saveDeferred;
    }
  }

  ngOnInit() {
    this.initPush.receiveMessage().subscribe((payload: any) => {
      const type: string = payload.data.type;
      if (type.toLowerCase() === 'promocion') {
        const action = JSON.parse(payload.data.action);
        this.toastSv.propsPromo = {
          url: `market-place/products/${action.store || action.user}/${
            action.product
          }`,
        };
        this.toastSv.show(payload.data.description, 'Ver Producto');
      } else if (type.toLowerCase() === 'expiracion') {
        this.toastSv.propsPromo = {
          url: `/login/perfil/directorio`,
        };
        this.toastSv.show(payload.data.description, 'Ir a Directorios');
      } else if (type.toLowerCase() === 'expiracion-tienda') {
        this.toastSv.propsPromo = {
          url: `/login/perfil/tienda`,
        };
        this.toastSv.show(payload.data.description, 'Ir a Tiendas');
      }
    });
  }

  async downloadPwa() {
    if (this.deferredPrompt !== null) {
      this.deferredPrompt.prompt();
      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === 'accepted') {
      }
    }
  }

  async initServices() {
    await this.idiomaService.initLangage();
    await this.paisService.initCountry();
  }
}
