import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./generales/generales.module').then((m) => m.GeneralesModule),
  },
  {
    path: 'market-place',
    loadChildren: () =>
      import('./market-place/home/pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
    pathMatch: 'prefix',
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/pages/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./blog/pages/blog.module').then((m) => m.BlogModule),
    pathMatch: 'prefix',
  },
  {
    path: 'directorios',
    loadChildren: () =>
      import('./directorio/directorio.module').then((m) => m.DirectorioModule),
    pathMatch: 'prefix',
  },
  {
    path: 'bolsa-de-trabajo',
    loadChildren: () =>
      import('./bolsa-trabajo/bolsa-trabajo.module').then(
        (m) => m.BolsaTrabajoPageModule
      ),
  },
  {
    path: 'educacion',
    loadChildren: () =>
      import('./educacion/educacion.module').then((m) => m.EducacionModule),
  },
  {
    path: 'category-list',
    loadChildren: () =>
      import('./generales/category-list/category-list.module').then(
        (m) => m.CategoryListPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
