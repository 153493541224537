import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { Messaging, onMessage, getToken, getMessaging } from 'firebase/messaging';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class InitPushNotificationService{
  public msgFirebase: Messaging;
  public tokenUser:string;
  readonly vapidkey = environment.firebaseConfig.vapidKey;
  constructor(
    private platform: Platform
  ) { 
    if(!this.platform.is('ios') && !this.platform.is('android') && !this.platform.is('iphone')){
      const app = initializeApp(environment.firebaseConfig);
      this.msgFirebase = getMessaging(app);
    }
  }

  requestPermission() {
    return new Promise(async (resolve, reject) => {
      if(this.platform.is('ios') || this.platform.is('android') || this.platform.is('iphone')){
        reject(new Error('Notificaciones no permitidas para ios/android'))
      }
      const permiss = await Notification.requestPermission();
      if(permiss === 'granted'){
        const tokenFirebase = await getToken(this.msgFirebase, {vapidKey: this.vapidkey});
        this.tokenUser = tokenFirebase;
        resolve(tokenFirebase);
      }else{
        reject(new Error('Acceso denegado'))
      }
    })
  }

  

  private messageObservable = new Observable(observe => {
    onMessage(this.msgFirebase, payload => {
      observe.next(payload);
    })
  });

  receiveMessage(){
    return this.messageObservable;
  }

  redirectTapNoti(){
  }

}
