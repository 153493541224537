import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class PaisService {
  /* Siglas según la Lista de códigos de idioma ISO 3166-1 */
  country: any = '/assets/img/flags/venezuela.svg';
  countrys = [
    {
      country: 'USA',
      code: 'US',
      prefPhone: '+1',
      img: '',
    },
    // {
    //   country: 'Spain',
    //   code: 'es',
    //   prefPhone: '+34',
    //   img: './assets/img/flags/spain.svg',
    // },
    // {
    //   country: 'USA',
    //   code: 'eu',
    //   prefPhone: '+1',
    //   img: './assets/img/flags/usa.svg',
    // },
    // {
    //   country: 'Portugal',
    //   code: 'pt',
    //   prefPhone: '+351',
    //   img: './assets/img/flags/portugal.svg',
    // },
    // {
    //   country: 'Germany',
    //   code: 'de',
    //   prefPhone: '+49',
    //   img: './assets/img/flags/germany.svg',
    // },
    // {
    //   country: 'Venezuela',
    //   code: 've',
    //   prefPhone: '+58',
    //   img: './assets/img/flags/venezuela.svg',
    // },
  ];

  constructor(private storageService: StorageService) {}

  async findNumber(social, local?) {
    if (!social.url) return false;
    //Compruebo la url de whatsapp
    if (social.url.includes('https') && social.url.includes('+')) return social;
    // compruebo un numero cualquiera si viene con codigo

    if (social.url.includes('+')) return social;
    const getLocal = local;
    const code = this.countrys.find((item) => item.country === getLocal);
    const findIndex = social.url.indexOf('=');

    let prefPhone = code ? code.prefPhone : '';
    let fixUrl = social.url.slice(0, findIndex + 1);
    prefPhone += social.url.slice(findIndex + 1, social.url.length);
    fixUrl += prefPhone;
    social.url = fixUrl;
    return social;
  }

  async initCountry() {
    const country = await this.storageService.get('country');
    await this.changeCountry(country);
  }

  async changeCountry(country: string) {
    await this.storageService.set('country', country);
    this.country = await this.selectCountry(country);
  }

  async selectCountry(country: string) {
    return this.countrys.filter((element) => element.code === country)[0];
  }
}
