import { Injectable } from '@angular/core';

import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading: any;

  constructor(public loadingCtrl: LoadingController) {}

  async show(message?: string) {
    this.loading = await this.loadingCtrl.create({
      message: message ?? 'Por favor espere',
    });
    await this.loading.present();
  }

  async hide() {
    if(this.loading){
      await this.loading.dismiss();
    }
  }
}
