import { Injectable } from '@angular/core';

import { NavController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  propsPromo;

  constructor(
    private toastController: ToastController,
    private navCtrl: NavController
  ) {}

  async show(message?: string, text?) {
    const toast = await this.toastController.create({
      message: message ?? 'Su petición ha sido exitosa',
      color: 'warning',
      duration: 4000,
      buttons: this.propsPromo ? [
        {
          side: 'end',
          text,
          handler: () => {
            this.seeNotify();
          }
        },
    ] : []
    });
    toast.present();
  }

  async error(message?: any[]) {
    let newMessage = '';
    if (message) {
      console.log(message);
      
      message.map((msj) => {
        newMessage += msj.message + '\n';
      });
    }
    const toast = await this.toastController.create({
      message: newMessage
        ? newMessage
        : 'Ha ocurrido un error inesperado\nPor favor vuela a intentarlo',
      color: 'danger',
      duration: 4000,
    });
    toast.present();
  }

  seeNotify(){
    this.navCtrl.navigateForward(this.propsPromo.url)
  }
}
